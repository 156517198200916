import React from 'react'
import Layout from '../components/Layout'

import styled from 'styled-components'

import Container from '../components/Container'

export default () => (
	<Layout>
		<NotFoundContainer>
			<h1>Page not found</h1>
			<p>You just hit a route that doesn&#39;t exist...</p>
		</NotFoundContainer>
	</Layout>
)

const NotFoundContainer = styled(Container)`
	text-align: center;
	padding-top: 60px;
`

